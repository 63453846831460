<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>StreetMetrics Pipeline</h2>

        <div>
          <button @click="loadJobs()" class="btn btn-sm btn-primary ms-2">
            Refresh
          </button>
        </div>
      </div>

      <div>
        <router-link :to="{ name: 'new-campaign-job' }" class="btn btn-secondary me-2">
          New Campaign Job
        </router-link>

        <router-link :to="{ name: 'backfill-job' }" class="btn btn-secondary">
          Backfill Job (in development)
        </router-link>
      </div>
    </div>

    <!-- New Query Results Table -->
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Query ID</th>
            <th>Database</th>
            <th>User</th>
            <th>Role</th>
            <th>Status</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Duration (ms)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in jobs" :key="job.QUERY_ID">
            <td>
              <span class="text-primary cursor-pointer" 
                    @click="showQueryText(job)" 
                    title="Click to view query">
                {{ job.QUERY_ID }}
              </span>
            </td>
            <td>{{ job.DATABASE_NAME }}</td>
            <td>{{ job.USER_NAME }}</td>
            <td>{{ job.ROLE_NAME }}</td>
            <td>
              <span :class="getStatusClass(job.EXECUTION_STATUS)">
                {{ job.EXECUTION_STATUS }}
              </span>
            </td>
            <td>{{ formatDateTime(job.START_TIME) }}</td>
            <td>{{ job.END_TIME ? formatDateTime(job.END_TIME) : '' }}</td>
            <td>{{ job.TOTAL_ELAPSED_TIME }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Query Text Modal -->
    <div class="modal fade" id="queryTextModal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Query Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <pre><code>{{ selectedQueryText }}</code></pre>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import dayjs from "dayjs";
import streetmetrics from "@/model";
import PipelineJobsList from "@/views/pipeline/PipelineJobsList.vue";
import JobDashboard from "@/views/pipeline/JobDashboard.vue";
import RunDayModal from "@/views/pipeline/RunDayModal.vue";
import CustomerFormModal from "@/components/CustomerFormModal.vue";
import AppUtil from "@/utils/AppUtil";
import JobsByMarket from "@/views/pipeline/JobsByMarket.vue";
import JobsByDate from "@/views/pipeline/JobsByDate.vue";
import ReportExplorer from "@/views/pipeline/ReportExplorer.vue";
import CampaignRefreshJobs from "@/views/pipeline/CampaignRefreshJobs.vue";

export default {
  name: 'Pipeline',
  components: {
    CampaignRefreshJobs,
    ReportExplorer,
    JobsByDate,
    JobsByMarket,
    CustomerFormModal,
    RunDayModal,
    JobDashboard,
    PipelineJobsList
  },
  data() {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      currentTab: 'dashboard',
      jobs: [],
      showRunDayModal: false,
      selectedQueryText: '',
      queryModal: null
    }
  },
  mounted() {
    this.loadJobs()
    streetmetrics.eventDispatcher.on('refreshJobsEvent', this.loadJobs)
    this.queryModal = new bootstrap.Modal(document.getElementById('queryTextModal'));
  },
  beforeUnmount() {
    streetmetrics.eventDispatcher.off('refreshJobsEvent', this.loadJobs)
  },
  methods: {
    loadJobs() {
      this.model.loading = true
      this.service.loadJobsSF().then((response) => {
        this.jobs = response
        this.model.loading = false
      }).catch((error) => {
        this.model.loading = false
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying load the jobs.';
      });
    },

    isDeveloper() {
      return this.model.isDeveloper();
    },

    onRunDateConfirmed(job) {
      const activeMarkets = this.model.markets.filter(market => (market.metaData && market.metaData.isActive))
      const startDate = job.date
      const endDate = job.endDate
      const runType = job.runType
      const jobType = job.jobType

      let dateStrings = [startDate]

      if (endDate) {
        dateStrings = streetmetrics.utils.app.getDateStrings(startDate, endDate)
      }

      const promises = [];

      for (let i = 0; i < activeMarkets.length; i++) {
        const activeMarket = activeMarkets[i];

        for (let j = 0; j < dateStrings.length; j++) {
          const date = dateStrings[j];

          const request = {
            market: activeMarket.marketHash,
            marketId: activeMarket.marketId,
            startDate: date,
            runBy: this.model.user.displayName,
            runType: runType,
            jobType: jobType
          }

          const url = new AppUtil().getPipelineUrl() + '/api/run-market'
          const promise = this.service.post(url, request);
          promises.push(promise);
        }
      }

      Promise.all(promises).then((response) => {
        this.model.toast.background = 'bg-green';
        this.model.toast.message = 'Jobs sent';
        this.loadJobs()
      }).catch((error) => {
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying to run this date. Please try again later.';
      });
    },

    formatDateTime(dateTime) {
      return dateTime ? dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss') : '';
    },

    getStatusClass(status) {
      const classes = {
        'SUCCESS': 'text-success',
        'RUNNING': 'text-primary',
        'FAILED': 'text-danger',
        'ERROR': 'text-danger'
      };
      return classes[status] || 'text-secondary';
    },

    showQueryText(job) {
      this.selectedQueryText = job.QUERY_TEXT;
      this.queryModal.show();
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
