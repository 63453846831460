import axios from 'axios';
import AppUtil from '@/utils/AppUtil';

export default class BackfillService {
  constructor() {
    this.appUtil = new AppUtil();
  }

  async post(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      return response.data;
    } catch (error) {
      console.error('BackfillService error:', error);
      throw error;
    }
  }

  async uploadFiles(formData) {
    return this.post(this.appUtil.getBackfillUrl() + '/upload', formData);
  }

  async testConnection() {
    try {
      const response = await axios.get(this.appUtil.getBackfillUrl() + '/hello');
      return response.data;
    } catch (error) {
      console.error('BackfillService test connection error:', error);
      throw error;
    }
  }
}
