<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>New Pipeline Job</h2>
      </div>
    </div>

    <div>
      <div class="mb-3">
        <label class="form-label">Markets</label>
        <div class="market-list">
          <div v-for="market in activeMarkets" 
               :key="market.marketId" 
               class="form-check">
            <input type="checkbox" 
                   class="form-check-input" 
                   :id="'market-' + market.marketId"
                   :value="market.marketId"
                   v-model="job.marketIds">
            <label class="form-check-label" :for="'market-' + market.marketId">
              {{ market.marketName }}
            </label>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="startDateInput" class="form-label">Start Date</label>
        <input v-model="job.startDate" type="text" class="form-control" id="startDateInput"
          placeholder="yyyy-mm-dd">
      </div>

      <div class="mb-3">
        <label for="endDateInput" class="form-label">End Date</label>
        <input v-model="job.endDate" type="text" class="form-control" id="endDateInput"
               placeholder="yyyy-mm-dd">
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Run Type</label>
      <select class="form-select" v-model="job.runType">
        <option value="STATIONARY">Stationary</option>
        <option value="TRANSIT">Transit</option>
      </select>
    </div>

    <div class="mb-3">
      <label class="form-label">Job Type</label>
      <select class="form-select" v-model="job.jobType">
        <option value="NEAR_TO_GOLD">Full Matching</option>
        <option value="BRONZE_TO_GOLD">Devices to Impressions</option>
        <option value="SF_BRONZE_TO_GOLD">SF Devices to Impressions</option>
      </select>
    </div>

<!--    <div v-if="job.runType === 'TRANSIT'" class="mb-3">-->
<!--      <label class="form-label">Cooldown Period</label>-->
<!--      <select class="form-select" v-model="job.cooldownInSeconds">-->
<!--        <option value="">None</option>-->
<!--        <option value="15">15 seconds</option>-->
<!--        <option value="60">1 minute</option>-->
<!--        <option :value="15 * 60">15 minutes</option>-->
<!--        <option :value="30 * 60">30 minutes</option>-->
<!--        <option :value="60 * 60">1 hour</option>-->
<!--      </select>-->
<!--    </div>-->

    <div class="mb-3">
      <label class="form-label">Customer (optional)</label>
      <select class="form-select" v-model="job.customerId">
        <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
        </option>
      </select>
    </div>

    <div v-if="job.customerId" class="mb-3">
      <label class="form-label">Campaign (optional)</label>
      <select class="form-select" v-model="job.campaignId">
        <option v-for="campaign in availableCampaigns" :value="campaign.campaignId">{{ campaign.campaignRef }}
        </option>
      </select>
    </div>

    <div class="mb-3">
      <button type="button" class="btn btn-secondary" style="min-width: 150px;" @click="onSaveClick">Save
      </button>

      <router-link :to="{ name: 'pipeline' }" class="btn btn-light ms-2">
        Cancel
      </router-link>
    </div>

  </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import AppUtil from "@/utils/AppUtil";
import Stationary from "@/views/pipeline/Stationary.vue";
import dayjs from "dayjs";
import CampaignService from "@/services/CampaignService";
import streetmetrics from "@/model";

export default {
  name: 'PipelineJobForm',
  components: {Stationary},
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      campaigns: [],
      job: {
        marketIds: [],
        startDate: '',
        endDate: '',
        matchGroup: 'exposed',
        runType: 'STATIONARY',
        customerId: null,
        campaignId: null,
        jobType: 'NEAR_TO_GOLD',
      }
    }
  },
  computed: {
    activeMarkets() {
      return this.model.markets.filter(market => market.metaData && market.metaData.isActive);
    },
    availableCampaigns() {
      if (this.job.customerId) {
        return this.campaigns.filter(campaign => (campaign.customerId === this.job.customerId))
      }

      return []
    },
  },
  mounted () {
    this.loadCampaigns();
  },
  methods: {
    loadCampaigns() {
      this.model.loading = true
      let service = new CampaignService();
      service.list().then((response) => {
        streetmetrics.utils.string.sortByString(response.data, 'campaignRef');
        this.campaigns = response.data;
        this.model.loading = false
      }).catch((error) => {
        console.log('Error loading campaigns');
        console.log(error);
        this.model.loading = false
      });
    },
    onSaveClick() {
      let dateStrings = [this.job.startDate];

      if (this.job.endDate) {
        dateStrings = streetmetrics.utils.app.getDateStrings(this.job.startDate, this.job.endDate);
      }

      const promises = [];

      // For each selected market
      for (const marketId of this.job.marketIds) {
        const market = this.model.markets.find(m => m.marketId === marketId);
        
        // For each date in the range
        for (const dateString of dateStrings) {
          const request = {
            market: market.marketHash,
            marketId: market.marketId,
            startDate: dateString,
            matchGroup: this.job.matchGroup,
            runType: this.job.runType,
            runBy: this.model.user.displayName,
            customerId: this.job.customerId,
            campaignId: this.job.campaignId,
            jobType: this.job.jobType
          };

          const url = new AppUtil().getPipelineUrl() + '/api/run-market';
          const promise = this.service.post(url, request);
          promises.push(promise);
        }
      }

      Promise.all(promises)
        .then((response) => {
          this.model.toast.background = 'bg-green';
          this.model.toast.message = 'Jobs submitted successfully';
          this.$router.push({ name: 'pipeline' });
        })
        .catch((error) => {
          this.model.toast.background = 'bg-red';
          this.model.toast.message = 'There was an error trying to run this job. Please try again later.';
        });
    }
  }
}
</script>

<style scoped>
.market-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.form-check {
  padding: 0.5rem 1rem;
}

.form-check:hover {
  background-color: #f8f9fa;
}
</style>
