<template>
    <div class="pt-4 px-4">

        <div class="mb-3 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <h2>New Campaign Job</h2>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Job Type</label>
            <select class="form-select" v-model="job.jobType">
                <option value="BRONZE">Full Matching (for new inventory)</option>
                <option value="GOLD">Refresh Impressions (for existing inventory added to a flight/campaign)</option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">One Campaign ID</label>
            <input v-model="job.campaignId" type="text" class="form-control"
                placeholder="221023">
        </div>

        <div class="mb-3">
            <button type="button" class="btn btn-secondary" style="min-width: 150px;" @click="onSaveClick">Save
            </button>

            <router-link :to="{ name: 'pipeline' }" class="btn btn-light ms-2">
                Cancel
            </router-link>
        </div>

    </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import AppUtil from "@/utils/AppUtil";
import Stationary from "@/views/pipeline/Stationary.vue";
import dayjs from "dayjs";
import CampaignService from "@/services/CampaignService";
import streetmetrics from "@/model";

export default {
    name: 'CampaignJobForm',
    components: { Stationary },
    props: ['prop1', 'prop2'],
    data() {
        return {
            model: streetmetrics.model,
            service: new PipelineService(),
            campaigns: [],
            job: {
                marketId: null,
                startDate: '',
                endDate: '',
                matchGroup: 'exposed',
                runType: 'STATIONARY',
                customerId: null,
                campaignId: null,
                jobType: 'NEAR_TO_GOLD',
            }
        }
    },
    methods: {
        onSaveClick() {
            const request = {
                runBy: this.model.user.displayName,
                campaignId: this.job.campaignId,
                jobType: this.job.jobType
            }
            const url = new AppUtil().getPipelineUrl() + '/api/run-campaigns-sf'
            
            this.service.post(url, request).then((response) => {
                this.model.toast.background = 'bg-green';
                this.model.toast.message = response.message;
                this.$router.push({ name: 'pipeline' });
            }).catch((error) => {
                this.model.toast.background = 'bg-red';
                this.model.toast.message =
                    'There was an error trying to run this job. Please try again later.';
            });
        }
    }
}
</script>
